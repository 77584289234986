import { useLocation } from '@reach/router';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';

import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import {
  useIsDarkMode,
  useBasePath,
  useVitaReactiveVariableActions,
  useReadVitaReactiveVariable,
  useIsCrateApp,
  useIsCoverLetterEditor,
  useIsCoverLetterRoot,
} from '@xing-com/crate-lebenslauf-hooks';
import type { FrameProps } from '@xing-com/crate-lebenslauf-xinglets';
import { useHost } from '@xing-com/crate-xinglet';
import { IconHamburgerMenu, IconArrowRight } from '@xing-com/icons';
import { mediaWideNavless } from '@xing-com/layout-tokens';
import { usePopOver } from '@xing-com/pop-over';

import AnschreibenLogoDark from './assets/anschreiben-logo-dark.svg';
import AnschreibenLogoLight from './assets/anschreiben-logo-light.svg';
import appAppleTouchIcon from './assets/apple-touch-icon-precomposed.png';
import appFavicon from './assets/favicon.ico';
import LebenslaufLogoDark from './assets/lebenslauf-logo-dark.svg';
import LebenslaufLogoLight from './assets/lebenslauf-logo-light.svg';
import { Footer } from './footer';
import {
  GlobalStyles,
  StyledHeader,
  StyledMainContent,
  StyledNavigation,
  StyledLogo,
  StyledLogoLink,
  StyledIconButton,
  StyledLink,
  StyledIconNewChat,
  StyledIconNews,
  StyledIconTopicChannels,
  StyledMobileWrapper,
  StyledVitaButton,
  StyledBreweryButton,
  StyledMobileLogin,
  StyledDesktopLogin,
  StyledMenu,
  StyledMenuDesktop,
} from './frame.styled';

const useSelectedNavItem = (): string => {
  const { pathname } = useLocation();

  if (pathname === `/ratgeber/lebenslauf-aufbau-inhalt`) {
    return 'topicChannels';
  }

  if (pathname === `/ratgeber`) {
    return 'news';
  }

  if (pathname === `/vorlagen-und-muster`) {
    return 'newChat';
  }

  return '';
};

export const Frame: React.FC<FrameProps> = ({
  children,
  hideFooter,
  noMainContentWrapperStyles = false,
  isEditorPage = false,
  className,
}) => {
  const { formatMessage } = useIntl();
  const basePath = useBasePath();
  const { pathname } = useLocation();
  const isCoverLetterEditor = useIsCoverLetterEditor();
  const isCoverLetterRoot = useIsCoverLetterRoot();
  const { getHostname } = useHost();
  const isMobile = !useMatchMedia(mediaWideNavless); // be careful: during SSR it will be always true
  const isDarkMode = useIsDarkMode();
  const isCrateApp = useIsCrateApp();
  const { showAnschreibenBanner } = useReadVitaReactiveVariable();
  const { toggleShowAnschreibenBanner } = useVitaReactiveVariableActions();
  const selectedNavItem = useSelectedNavItem();
  const popOver = usePopOver();
  const MenuComponent: React.ElementType | 'string' = isMobile
    ? StyledMenu
    : StyledMenuDesktop;

  React.useEffect(() => {
    if (!isCrateApp) toggleShowAnschreibenBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCrateApp]);

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <link rel="shortcut icon" href={appFavicon} type="image/x-icon" />
        <link rel="apple-touch-icon-precomposed" href={appAppleTouchIcon} />
        <link
          rel="canonical"
          href={`https://${getHostname()}${pathname}`.replace(/\/$/, '')}
        />
      </Helmet>

      <StyledHeader>
        <StyledNavigation>
          <StyledLogoLink
            to={`${basePath}/`}
            aria-label={formatMessage({
              id: 'CRATE_LEBENSLAUF_NAVIGATION_LOGO',
            })}
          >
            <StyledLogo
              src={
                isCoverLetterRoot
                  ? isDarkMode
                    ? AnschreibenLogoDark
                    : AnschreibenLogoLight
                  : isDarkMode
                    ? LebenslaufLogoDark
                    : LebenslaufLogoLight
              }
              alt={formatMessage({
                id: 'CRATE_LEBENSLAUF_NAVIGATION_LOGO',
              })}
            />
          </StyledLogoLink>
          {isMobile && (
            <StyledMobileWrapper>
              <StyledDesktopLogin />
              <StyledIconButton
                icon={IconHamburgerMenu}
                size="large"
                onClick={popOver.togglePopOver}
                aria-label={formatMessage({
                  id: 'CRATE_LEBENSLAUF_NAVIGATION_OPEN_MENU',
                })}
              />
            </StyledMobileWrapper>
          )}
          {(isMobile || (!isMobile && !isEditorPage)) && (
            <MenuComponent
              dimmerTitle="Close menu"
              onOutsideClick={popOver.handleHide}
              show={popOver.show}
              triggerRef={popOver.triggerRef}
            >
              {!isCoverLetterRoot && (
                <>
                  <StyledLink
                    to={`${basePath}/ratgeber/lebenslauf-aufbau-inhalt`}
                    $opened={selectedNavItem === 'topicChannels'}
                  >
                    <StyledIconTopicChannels width={24} height={24} />
                    <FormattedMessage id="CRATE_LEBENSLAUF_NAVIGATION_STRUCTURE" />
                  </StyledLink>
                  <StyledLink
                    to={`${basePath}/vorlagen-und-muster`}
                    $opened={selectedNavItem === 'newChat'}
                  >
                    <StyledIconNewChat width={24} height={24} />
                    <FormattedMessage id="CRATE_LEBENSLAUF_NAVIGATION_TEMPLATES" />
                  </StyledLink>
                </>
              )}

              <StyledLink
                to={`${basePath}/ratgeber`}
                $opened={selectedNavItem === 'news'}
              >
                <StyledIconNews width={24} height={24} />
                <FormattedMessage id="CRATE_LEBENSLAUF_NAVIGATION_ADVISORY" />
              </StyledLink>

              <StyledMobileLogin />

              {!isEditorPage && !isCoverLetterRoot ? (
                <StyledVitaButton
                  variant="primary"
                  size="medium"
                  sizeConfined="small"
                  data-qa="lebenslauf-header-cta"
                  to={`${basePath}/neu?sc_o=lebenslauf_home_create_cv_click&sc_o_PropActionOrigin=lebenslauf_home_top_navigation`}
                >
                  <FormattedMessage id="CRATE_LEBENSLAUF_NAVIGATION_CREATE_CV" />
                </StyledVitaButton>
              ) : null}
              {!isEditorPage && isCoverLetterRoot ? (
                <StyledVitaButton
                  variant="primary"
                  size="medium"
                  sizeConfined="small"
                  data-qa="anschreiben-header-cta"
                  to={`${basePath}/anschreiben`} // TODO: add tracking params
                >
                  <FormattedMessage id="CRATE_LEBENSLAUF_NAVIGATION_CREATE_COVER_LETTER" />
                </StyledVitaButton>
              ) : null}
              {isCoverLetterEditor && isEditorPage && (
                <StyledBreweryButton
                  variant="proJobs"
                  to={`${basePath}/neu`}
                  size="medium"
                  sizeConfined="small"
                  icon={IconArrowRight}
                >
                  <FormattedMessage id="CRATE_VITA_TEMPLATE_TAG" />
                </StyledBreweryButton>
              )}
            </MenuComponent>
          )}
        </StyledNavigation>
      </StyledHeader>

      <StyledMainContent
        $noMainContentWrapperStyles={noMainContentWrapperStyles}
        $hasBanner={!!showAnschreibenBanner}
        className={className}
      >
        {children}
      </StyledMainContent>

      {hideFooter ? null : <Footer />}
    </>
  );
};
