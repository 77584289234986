import styled from 'styled-components';

import { Button } from '@xing-com/crate-lebenslauf-button';
import { mediaWideNavless, mediaConfined } from '@xing-com/layout-tokens';
import { Menu } from '@xing-com/menu';
import { SkeletonButton } from '@xing-com/skeleton';
import {
  spaceM,
  spaceXL,
  space3XL,
  space4XL,
  scale320,
  xdlColorText,
  scale130,
  scale160,
} from '@xing-com/tokens';

export const StyledLoginButton = styled(Button)<{
  $isOnProgressBar?: boolean;
}>`
  flex-shrink: 0;

  @media ${mediaWideNavless} {
    margin-left: ${({ $isOnProgressBar }) => ($isOnProgressBar ? spaceXL : 0)};
    margin-right: ${({ $isOnProgressBar }) =>
      $isOnProgressBar ? 0 : space3XL};
  }
`;

export const StyledProfileMenu = styled.button<{
  $isOnProgressBar?: boolean;
}>`
  flex-shrink: 0;
  padding: 0;
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
  display: flex;

  @media ${mediaWideNavless} {
    margin-left: ${({ $isOnProgressBar }) => ($isOnProgressBar ? spaceXL : 0)};
    margin-right: ${({ $isOnProgressBar }) =>
      $isOnProgressBar ? 0 : space3XL};
  }
`;

export const StyledMenu = styled(Menu)<{
  $isOnProgressBar?: boolean;
}>`
  @media ${mediaConfined} {
    position: fixed;
    top: 75px;
    right: ${({ $isOnProgressBar }) =>
      $isOnProgressBar ? 'calc((50vw - var(--previewWidth)) / 2)' : space4XL};
  }

  @media ${mediaWideNavless} {
    right: ${({ $isOnProgressBar }) =>
      $isOnProgressBar ? 'calc((50vw - var(--previewWidth)) / 2)' : scale320};
  }
`;

export const StyledLink = styled.a`
  display: block;
  padding: ${spaceM} 0;
  color: ${xdlColorText};
  margin-right: ${spaceXL};
  font-size: 16px;

  @media ${mediaWideNavless} {
    font-size: 14px;
  }
`;

export const StyledSkeletonButton = styled(SkeletonButton)<{
  $isOnProgressBar?: boolean;
}>`
  flex-shrink: 0;
  width: ${scale130};

  @media ${mediaWideNavless} {
    width: ${scale160};
    margin-left: ${({ $isOnProgressBar }) => ($isOnProgressBar ? spaceXL : 0)};
    margin-right: ${({ $isOnProgressBar }) => ($isOnProgressBar ? 0 : spaceXL)};
  }
`;
