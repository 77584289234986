import { Link } from '@reach/router';
import styled, { css, createGlobalStyle } from 'styled-components';

import { IconButton, Button as BreweryButton } from '@xing-com/button';
import { Button as VitaButton } from '@xing-com/crate-lebenslauf-button';
import {
  Login,
  mainContentWrapperStyles,
} from '@xing-com/crate-lebenslauf-components';
import { IconNewChat, IconNews, IconTopicChannels } from '@xing-com/icons';
import {
  mediaConfined,
  mediaWideNavless,
  mediaTopBarVisible,
} from '@xing-com/layout-tokens';
import { Menu } from '@xing-com/menu';
import {
  spaceM,
  space3XL,
  space4XL,
  space5XL,
  spaceL,
  spaceS,
  spaceXL,
  xdlColorBackground,
  xdlColorBorderSoft,
  xdlColorText,
} from '@xing-com/tokens';

export const GlobalStyles = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
`;

export const StyledHeader = styled.header`
  background-color: ${xdlColorBackground};
  border-bottom: 1px solid ${xdlColorBorderSoft};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
`;

export const StyledNavigation = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  height: 54px;

  @media ${mediaWideNavless} {
    padding: 0 ${spaceL};
    height: 88px;
  }

  @media ${mediaWideNavless} {
    padding: 0 ${space4XL};
  }
`;

export const StyledLogoLink = styled(Link)`
  height: 54px;
  display: flex;
  align-items: center;

  @media ${mediaWideNavless} {
    height: 42px;
  }
`;

export const StyledLogo = styled.img`
  height: 33px;
  margin-left: ${spaceL};

  @media ${mediaWideNavless} {
    height: 42px;
    margin-left: 0;
  }
`;

export const StyledMobileLogin = styled(Login)`
  @media ${mediaTopBarVisible} {
    display: none;
  }
`;

export const StyledDesktopLogin = styled(Login)`
  @media ${mediaWideNavless} {
    display: none;
  }
`;

export const StyledMenuDesktop = styled.div<{
  $hideOnDesktop: boolean;
}>`
  display: ${(props) => (props.$hideOnDesktop ? 'none' : 'flex')};
  align-items: center;
  flex-direction: row;
  border-top: 0 none;
  padding: 0;
`;

export const StyledLink = styled(Link)<{
  $opened?: boolean;
}>`
  ${(props) => props.$opened && `font-weight: bold;`};
  margin-right: ${space3XL};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${xdlColorText};
  font-size: 16px;
  padding: ${spaceM} 0;

  @media ${mediaWideNavless} {
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    padding: 0;
  }
`;

const iconStyles = css`
  margin-right: ${spaceS};

  @media ${mediaWideNavless} {
    margin-right: 0;
  }
`;

export const StyledIconNewChat = styled(IconNewChat)`
  ${iconStyles}
`;
export const StyledIconNews = styled(IconNews)`
  ${iconStyles}
`;
export const StyledIconTopicChannels = styled(IconTopicChannels)`
  ${iconStyles}
`;

export const StyledIconButton = styled(IconButton)`
  @media ${mediaWideNavless} {
    display: none;
  }

  margin-left: ${spaceL};
`;

export const StyledMainContent = styled.main<{
  $noMainContentWrapperStyles: boolean;
  $hasBanner: boolean;
}>`
  ${({ $noMainContentWrapperStyles, $hasBanner }) => {
    if (!$noMainContentWrapperStyles) {
      return css`
        ${mainContentWrapperStyles}
      `;
    }
    return css`
      margin-bottom: ${$hasBanner ? space5XL : 0};
    `;
  }}
`;

export const StyledMobileWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const StyledVitaButton = styled(VitaButton)`
  margin-top: ${spaceXL};

  @media ${mediaWideNavless} {
    margin-top: 0;
  }
`;

export const StyledBreweryButton = styled(BreweryButton)`
  margin-top: ${spaceXL};

  @media ${mediaWideNavless} {
    margin-top: 0;
  }
`;

export const StyledMenu = styled(Menu)`
  @media ${mediaConfined} {
    position: fixed;
    top: 70px;
    right: ${spaceL};
  }
`;
